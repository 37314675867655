import { Divider, Image, Typography } from "antd";
import * as React from "react";
import Page from "../components/page";
import terms from "../images/terms.jpg";

const { Title, Paragraph } = Typography;
const TermsPage = () => (
  <Page title="ルールとマナー | 朝鮮カラオケ大会">
    <Title>ルールとマナー</Title>
    <Divider />
    <Paragraph>
      朝鮮カラオケ大会では、参加される全員が心地よく楽しんでいただくために、守るべきルールとマナーを設けています。イベント参加前にかならずご一読ください。{" "}
    </Paragraph>
    <Image style={{ width: "100%" }} width="100" src={terms} />
  </Page>
);
export default TermsPage;
